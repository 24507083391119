import React, { Component } from 'react';
import 'antd/dist/antd.min.css';
import { Button, Tooltip, Row, Image, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faDiscord, faInstagram, } from '@fortawesome/free-brands-svg-icons'


class Footer extends Component {
    render() {
        return <div className="footer" style={{}}>
            <Row align='center'>
                <Col span={24} style={{ }}>
                    Tiny Monster Art Club, Copyright 2022
                    <a className="footer-button" href="https://discord.gg/r3X3HWDAQw">
                        <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a className="footer-button" href="https://www.instagram.com/tinymonsterart">
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a className="footer-button" href="https://www.twitter.com/tinymonsterart">
                        <FontAwesomeIcon icon={faTwitter} />
                    </a>
                </Col>
            </Row>
        </div>
    }
}

export default Footer;