import './App.css';
import Header from './components/header'
import Body from './components/body'
import Library from './components/library'
import Monsters from './components/monsters'
import Arena from './components/arena'
import Tavern from './components/tavern'
import Footer from './components/footer'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (<Router>
    <div className="App">
      <Header>
      </Header>
      <Routes >
        <Route path="/" element={<Body />}>
        </Route>
        <Route path="/library" element={<Library />}>
        </Route>
        <Route path="/monsters" element={<Monsters />}>
        </Route>
        <Route path="/arena" element={<Arena />}>
        </Route>
        <Route path="/tavern" element={<Tavern />}>
        </Route>
        <Route path="/*" element={<Body />}>
        </Route>
      </Routes >
      <Footer></Footer>
    </div>
  </Router>
  );
}

export default App;
