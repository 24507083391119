import React, { Component } from 'react';
import 'antd/dist/antd.min.css';
import { Button, List, Typography, Divider, Row, Col } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Body extends Component {
  render() {

    return <div className="main">
      <Row style={{ paddingTop: "36px" }}>
        <div className="hero-image-div" style={{}}>
          <img className="hero-image" style={{ borderColor: "antiquewhite", borderRadius: "5px" }} src='./example.png' />
        </div>
        <div style={{}} className='hero-body'>
          Welcome, this is a tiny NFT art collection project and monster adventure game. Launched in phases, art owners will have the ability to participate in PVE And PVP events.
        </div>
        <div className="hero-image-div" style={{}}>
          <img loading="lazy" className="hero-image" style={{ borderColor: "antiquewhite", borderRadius: "5px" }} src='./example.png' />
        </div>
      </Row>
      <div className="main-content">
        <Row className='main-break'>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="hero" span={24}>
            The Story
          </Col>
        </Row>
        <Row>
          <div style={{ width: "15%" }}></div>
          <div style={{ width: "70%" }}>
            <div className='center-div'>
              <div className="hero-body-wrapper">
                Centuries in the past, tiny monsters roamed the land. Wizards, Warlocks, Mages and Cosmic magic wielders worked side by side with the monsters to battle, adventure and explore. Over time, factions developed among the magic diciplines. These factions began to war. One factor in particular realized the common elements were never going to be enough to win, and summoned chaos magic from a broken moon. Cracking the sky, they unleashed the end on almost all monsters and people. Those who survived used their last bits of magic to preserve the souls of the monsters into piece of fine art. That magic has been lost to time, but the power inside the art remains.
              </div></div>
          </div>
          <div style={{ width: "15%" }}></div>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="hero" span={24}>
            The Monsters
          </Col>
        </Row>
        <Row className="content-row">
          <div className="custom-column">
            <div className='center-div'>
              <div className="body-wrapper body-text">
                Tiny Monsters inside a tiny piece of art. Each minted art piece will have a random combination of enviroments and floura. These can range from tranquil summer days to the epoch of the unraveling. Check out the <Link className='links' to="/library">Library</Link> for more information on the monsters.
                <div style={{ textAlign: "left", marginTop: "24px", marginLeft: "24px" }}>
                  <ul>
                    <li>Win Battles</li>
                    <li>Compete in Tournaments</li>
                    <li>Win Prizes</li>
                  </ul>
                </div></div>
            </div>
          </div>
          <div className="custom-column">
            <div className='body-image'>
              <img loading="lazy" style={{ borderColor: "antiquewhite", borderRadius: "5px" }} src='./grouping.png' /></div>
          </div>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col style={{ width: "100%" }}>
            <img loading="lazy" className="vine" src='./Vines.png' preview='false'></img>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="hero" span={24}>
            The Game
          </Col>
        </Row>
        <Row className="content-row">
          <div className="custom-column">
            <div className='body-image-flipped'>
              <img loading="lazy" style={{ borderColor: "antiquewhite", borderRadius: "5px" }} src='./Tavern.png' /></div>
          </div>
          <div className="custom-column">
            <div className='center-div-flipped'>
              <div className="body-wrapper body-text-flipped">
                During the later phases, art owners will be able to go on adventures with thier monsters. This stage will more of a game where setting up your monster for success can be rewarded. Items found can be claimed to the blockchain to be used in the Arena.
                <div style={{ textAlign: "left", marginTop: "24px", marginLeft: "24px" }}>
                  <ul>
                    <li>Win Battles</li>
                    <li>Compete in Tournaments</li>
                    <li>Win Prizes</li>
                  </ul>
                </div>
              </div></div>
          </div>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col style={{ width: "100%" }}>
            <img loading="lazy" className="vine" src='./Vines.png' preview='false'></img>
          </Col>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="hero" span={24}>
            The Arena
          </Col>
        </Row>
        <Row className="content-row">
          <div className="custom-column">
            <div className='center-div'>
              <div className="body-wrapper body-text"> The final phase will involve battling other monsters. The system will allow you to setup your monster for defensive battles, or you can proactively battle other monsters and get your name on the leaderboard. Tournements will be recorded on the chain forever.
                <div style={{ textAlign: "left", marginTop: "24px", marginLeft: "24px" }}>
                  <ul>
                    <li>Win Battles</li>
                    <li>Compete in Tournaments</li>
                    <li>Win Prizes</li>
                  </ul>
                </div>
              </div></div>
          </div>
          <div className="custom-column">
            <div className='body-image'>
              <img loading="lazy" style={{ borderColor: "antiquewhite", borderRadius: "5px" }} src='./Arena.png' /></div>
          </div>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col className="hero" span={24}>
            Join Today
          </Col>
        </Row>
        <Row>
          <Col className="body-text-footer" style={{}} span={24}>
            Help release the power stored inside the forgotten artwork. Let the trapped souls of the past once again flow! Contact us on Discord for more information!
          </Col>
        </Row>

      </div>
    </div>
  }
}

export default Body;