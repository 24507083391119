import React, { Component } from 'react';
import 'antd/dist/antd.min.css';
import './tavern.css'
import { Button, List, Typography, Divider, Row, Col } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class Tavern extends Component {
  render() {
    return <div className="main">
      <Row style={{ paddingTop: "36px" }}>
        <Col className="hero" span={24}>
          Welcome to the Tavern
        </Col>
      </Row>
      <div className="main-content">

      </div>
    </div>
  }
}

export default Tavern;