import React, { Component } from 'react';
import 'antd/dist/antd.less';
import './library.css';
import MonsterDex from './monsterdex';
import { Anchor, Divider, Row, Col } from 'antd';
const { Link } = Anchor;
class Library extends Component {
  render() {

    return <div className="main-library">
      <Row>
        <Col className="hero-library" span={24}>
          The Library - WIP
        </Col>
      </Row>
      <Row>
        <Col className="hero-library-sm" style={{ }} span={24}>
          The library contains information about all over recorded history. As stories are told and uncovered, they will be added here to the library to be shared with all.
        </Col>
      </Row>
        <Row className='main-break-library'>
        </Row>
      <div className="main-content-library">
        <Row className='main-row'>
          <Col span={6} className="menu-col">
            <Anchor className='menu-library'>
              <div>Tools</div>
              <Link href="#index" title="Spellbook" />
              <div>The History Books</div>
              <Link href="#oldtimes" title="Time of Monsters" />
              <Link href="#magic" title="Witches and Warlocks" />
              <Link href="#monsters" title="The Monsters" />
              <Link href="#factions" title="Factions" />
              <Link href="#war" title="The Great War" />
              <Link href="#end" title="The End" />
              <Link href="#sealed" title="The Sealing of Magic" />
              <Link href="#dark" title="Darkness" />
              <Link href="#new" title="New Era" />
              <Link href="#today" title="Magic Unleashed" />
            </Anchor>
          </Col>
          <Col span={18}>
            <Row id="index" className='body-text-index'>
              <div className='library-header-title' style={{ }}>The Spellbook</div>
              <div style={{ textAlign: "center",width: "100%" }}>
                <MonsterDex />
              </div>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="oldtimes" className='body-text-library'>
              <div className='library-header-title'>Before Time Existed</div>
              Before the world burned and all of the light was exstinguished, there was a time of magic and never ending adventure.
              A world of beasts, monsters, magic and wonder. A world born of magic. It eminated from every corner.
              The people of the world learned to harness it's power to build floating cities and underground wonders. Everyone had some level of magical
              power. Since the energy flowed through the land and the air and the water, from birth, every one and every creature had magical energy. These bonds of magic
              allowed the more advanced magicians to form strong relationships with many different monsters. This bond was called True Linking. A strong enough magical caster
              could hear the thoughts of their monster partner and the monster could speak and understand the caster. It also granted incredible healing and life extending potential
              as the caster would share the powers of the monster they bonded with. This bond came a cost, if the monster or the caster were killed, the other would return
              to the world as pure magic.
              <Divider />
              As the world progressed and resources for some started to become scarce, instead of turning to sustainable solutions, the world turned towards cruelty.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="magic" className='body-text-library'>
              <div className='library-header-title'>Magic Ruled the Land</div>
              The world was made up of many magical people. Witches, Wizards, Casters all studied, experimented and progressed the world of magic at different schools and underground labs.
              The Warlocks of the Northen lands used the power of the earth to build structures and shelters out of nothing but dirt. They could even animate rocks and other elements into new monsters.
              Witches and Wizards focused on the core elements of fire and water to lend help with providing power to the world. The heads of these groups were known as the first ones, they were the first
              to master a specific element and become timeless historians and casters that would train and guide other for all of time.
              <Divider />
              Hazel of Forest. Merrog of Earth, Joanna of Water, Ireaze of Thunder, Balgor of Fire, Koompar of Wind, Ydregz of Ice. All ensured the elements remained in balance.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="monsters" className='body-text-library'>
              <div className='library-header-title'>A Tale of Monsters</div>
              IN PROGRESS
            </Row>        <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="factions" className='body-text-library'>
              <div className='library-header-title'>The Great Factions</div>
              Each element would push the caster down a particular path. Today some say they are aligned by the stars in the sky and locked to some sort of greater power that drives their personality. The same
              was said about the elements. Fire casters were often impusive and self destructive. Water and Forest elemental casters were often seen as caring and nuturing. The elements were not always known,
              some took centuries or more to discover and master. The final element, Chaos, was no discovered until very late in the cycle of the first time. Some say is was a new element introduced by intruders
              from another plane of existance. As most of history was lost, this is not yet known.
              <Divider />
              These elemental breakdowns natually began to form factions. Each group started off as loosly clusters stop overs for magic casters. Bars, Taverns and Inns that served as a resting place on long journeys.
              Over the centuries, these groups expanded and built cities and nations based around the magic. It spawned schools and competitions where students and masters could battle in magical arenas. Some of these relics are still present today as ruins.
              <Divider />
              While there was always competition and rivalry between the factions, they all worked together to solve problems and try and help society as a whole. It would have gone on like this, but far in the northern regions, the warlocks made
              a discory that would reshape the world. A new type of magic that would be called Chaos. It was found in a relic long buried, or perhaps placed to be found. No one will know. It's discovery had the same impact either way.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="war" className='body-text-library'>
              <div className='library-header-title'>The Final War</div>
              Chaos magic was unlike any magic found on in the world. It had no counter balance. Fire and Water, Earth and Wind. These balances promised a balanced world if used correctly. Chaos magic only consumed.
              There was no counter. The warlocks built temples deep into the mountians, far away from the eyes of the first ones and the rest of the casters. This is where they practiced the arts of chaos. This new magic
              twisted the warlocks both pysically and mentally. Great strength was pulled from the earth as they learned new spells and tactics. They even learned how to break the True Bond without killing the caster.
              <Divider />
              Eventually the twisted chaos faction decided they wanted all the power of all the other factions, and began the great magic war. The first ones had prophesised about the war, but did not know the scale, or the source, when the war came, it was already too late.
              All the other factions banded together to battle the warlocks. They had the numbers, but the warlocks were much stronger, and with chaos as the shield, they were hard to defeat. After a century of fighting, both sides were losing ground, but the warlocks had started to
              fall. Their leader, Merrog the Betrayer, relized they needed something more than just chaos to help them and called into the relic for guidance. The leader was then told of a spell, one that was so powerful, it would surely win the war.
              Merrog swiftly brought together the remaining elders and began to cast. Thousands of monsters were sacrificed in the casting, and when complete something terrible followed.
              <Divider />
              Near the front line of the war, a storm started to form. The first ones, looked on with fear. They could feel something greater their combimed power was about to appear. As the storm grew stonger and more chaotic, the sky cracked in two. A wound opened in the sky and it was as if all hope because to flow
              out of the world. From the gapping cut, poured countless monsters and elementals. Behind these monsters followed the Reapers. They were twisted specters that collected and feed on the souls of the dead, monsters and casters alike. As the army of creatures tore through all factions, Merrog realized what had been done.
              By then, it was too late.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="end" className='body-text-library'>
              <div className='library-header-title'>The Cataclsym</div>
              A portal to another time and place had opened, a place that only fed on chaos and death. It quickly began to consume all living beings. Forests turned to ash, oceans began to boil. The Reapers consumed all. The invaders began to setup an central staging area below  Merrog sent a messenger to the leaders of the other factions explaining what had been done, and it was decided the only chance they had
              was to close the portal by reversing the spell. The leaders all gathered at the Warlocks mountain temple as fast as they could.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="sealed" className='body-text-library'>
              <div className='library-header-title'>The Final Seal</div>
              Deep within the mountain, the remaining casters and first ones gathered for a final attempt to turn the tide of the war. They had to make an impossible choice, drawing from the remaining life on the planet, and using it to create a spell so power, it would seal the portal. and save the world. The cost would be great, most life would end, but those who would survive the spell could rebuild some day. The spell would focus on all magical beings. To save the monsters that populated the world, the casters would seal the souls of the monsters into imbued paintings. Once ready, the casters channeled all the remining magic in the world to seal the portal and drain the life from the monsters and other casters. The world would be safe again, but who would know.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="dark" className='body-text-library'>
              <div className='library-header-title'>The Dark Times</div>
              After the sealing, life slowly regained a foot hold. Non-magic folk began to rebuild villages in the ruins. Animals returned to the fields. Forests began to regrow. Life was harder without the power of magic, but people struggled on. The world was in ruins and magic was forgotten for a very long time. 
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="new" className='body-text-library'>
              <div className='library-header-title'>Minds Awakening</div>
              Much time passed, cities had been rebuilt. The forests had returned. The world had awakened again. Healed with time. Something began calling from the mountain. Deep inside. A pull that the poeople could not understand and could not resist. They began to dig. Generations of miners bore deep into the hills and mountains until one day, an underground city was discovered. Filled with artwork, that seemed alive in some way. When touched, you could feel the energy flow into your body. Magic still lived inside the paintings. Inside the city were texts and schematics for machines and spells that the people could not yet understand. They had no idea the power they could unleash.
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col span={24}>
                <img className="vine" src='./Vines.png' ></img>
              </Col>
            </Row>
            <Row id="today" className='body-text-library'>
              <div className='library-header-title'>New World</div>
              Once again, magic began to flow into the world. Deep within the soil the magic had waited for the right time. A time when monsters and casters might finally return. That time is now.
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  }
}

export default Library;