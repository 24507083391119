import React, { Component } from 'react';
import 'antd/dist/antd.less';
import './monsterdex.css';
import { Anchor, Divider, Row, Col } from 'antd';
const { Link } = Anchor;
class MonsterDex extends Component {
    render() {
        return <div className="dex-main"  >
            <div style={{ width: "100%" }}>
                <Row style={{}}>
                    <Col span={24} style={{}}>
                        <div className='dex-table'>
                            <div style={{ textAlign: "right", width: "50%", paddingRight: "40px", paddingTop: "80px" }}>
                                <div style={{ width: "220px", float: "right" }}>
                                    Water
                                    <br />
                                    Water elementals were one of the first to roam the world. After the cooling of the birth, water elements rose up to form oceans, lakes and rivers. They gave strenth to the rains and storms.
                                </div>
                            </div>
                            <div style={{ textAlign: "left", width: "50%", paddingLeft: "85px", paddingTop: "125px" }}>
                                <img style={{maxWidth: "125px" }} src='../elements/water element.png' />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row style={{ textAlign: "center" }}>
                    <Col span={24}>
                        
                    </Col>
                </Row>
            </div>
        </div >
    }
}

export default MonsterDex;