import React, { Component } from 'react';
import 'antd/dist/antd.less';
import { Link } from 'react-router-dom'
import './header.css';
import { Button, Drawer, Row, Col, Divider } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSailboat } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faDiscord, faBo, } from '@fortawesome/free-brands-svg-icons'
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }
    render() {
        return <div className="header">
            <Row align='center'>
                <Col span={12} className="header-title" style={{ textAlign: 'left' }}>
                    <Link to="/" className="title-link"><img className="header-logo" src='./favicon.png' /><div className="header-title-text">Tiny Monster Art Club</div></Link>
                </Col>
                <Col className='button-area' span={12} style={{}} align="middle">
                    <div className='bigButtons'>
                        <a href="https://docs.tinymonsterart.club/" className="header-buttons">WHITEPAPER</a>
                        <Link to="/library" className="header-buttons">LIBRARY</Link>
                        <a className='social-header' href="https://discord.gg/r3X3HWDAQw">
                            DISCORD
                        </a>
                        <a className="social-header" href="https://www.twitter.com/tinymonsterart">
                            TWITTER
                        </a>
                        <a className="social-header" href="https://www.opensea.com">
                            OS
                        </a>
                    </div>
                    <div className='littleButtons'>
                        <Button type="primary" onClick={() => this.setState({ visible: true })} icon={<MenuOutlined />} className="header-buttons">
                        </Button>
                        <Drawer className="header-drawer" title="Menu" placement="right" onClose={() => this.setState({ visible: false })} visible={this.state.visible}>
                            <a href="https://docs.tinymonsterart.club/" className="header-buttons">WHITEPAPER</a><Divider />
                            <Link to="/library" className="header-buttons">LIBRARY</Link><Divider />
                            <a className="header-buttons" href="https://discord.gg/r3X3HWDAQw">DISCORD</a><Divider />
                            <a className="header-buttons" href="https://www.twitter.com/tinymonsterart">TWITTER</a><Divider />
                            <a className="header-buttons" href="https://www.opensea.com">OPENSEA</a>
                        </Drawer>
                    </div>

                </Col>
            </Row>

        </div>
    }
}

export default Header;